<template>
  <div>
    <b-table-simple
      striped

      small
      responsive
      bordered
    >
      <b-thead>
        <b-th class="text-center" />
        <b-th>Lokasyon</b-th>
        <b-th class="width-200">
          İş Emri Adedi
        </b-th>
        <b-th class="width-200">
          SÜRE / DAKİKA
        </b-th>
        <b-th class="width-200">
          SÜRE / SAAT
        </b-th>
      </b-thead>
      <b-tbody>
        <template v-for="(line,key) in reportData.report">
          <b-tr
            :key="key"
          >
            <b-td class="text-center p-0 width-50">
              <b-button

                variant="flat-info"
                size="sm"
                pill
                class="btn-icon"
                @click="line.statusButton = !line.statusButton"
              >
                <template
                  v-if="line.statusButton"
                >
                  <FeatherIcon icon="EyeOffIcon" />
                </template>
                <template v-else>
                  <FeatherIcon icon="EyeIcon" />
                </template>
              </b-button>
            </b-td>
            <b-td>
              {{ line.title }}
            </b-td>
            <b-td>
              {{ line.orders |toNumber }} Adet
            </b-td>
            <b-td>
              {{ line.minutes | toNumber }} Dakika
            </b-td>
            <b-td>
              {{ line.hours | toNumber }} Saat
            </b-td>
          </b-tr>
          <b-tr
            v-if="line.statusButton"
            :key="'detail_' + key"
          >
            <b-td
              colspan="5"
              class="m-0 p-0"
            >
              <work-order-brand-table
                :data-key="key"
              />
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
      <b-tfoot>
        <b-tr>
          <b-td
            colspan="2"
            class="text-right"
          >
            <b>
              Toplamlar
            </b>
          </b-td>
          <b-td>
            <b>
              {{ reportData.totals.orders | toNumber }} Adet
            </b>
          </b-td>
          <b-td>
            <b>
              {{ reportData.totals.minutes | toNumber }} Dakika
            </b>
          </b-td>
          <b-td>
            <b>
              {{ reportData.totals.hours | toNumber }} Saat
            </b>
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTh, BThead, BTbody, BTr, BTd, BButton, BTfoot,
} from 'bootstrap-vue'
import WorkOrderBrandTable from '@/views/Reports/Service/ServiceWorkOrders/WorkOrderBrandTable.vue'

export default {
  name: 'WorkOrdersTable',
  components: {
    WorkOrderBrandTable,
    BButton,
    BTableSimple,
    BTh,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTfoot,
  },
  computed: {
    reportData() {
      return this.$store.getters['serviceWorkOrderReport/getReport']
    },
  },
}
</script>
