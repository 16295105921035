<template>
  <div>
    <b-card>
      <b-row class="d-flex align-items-center">
        <b-col>
          <b-form-group
            label="Başlangıç Tarihi"
            label-for="sdate"
          >
            <b-form-datepicker
              id="sdate"
              v-model="filterData.sdate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label="Bitiş Tarihi"
            label-for="edate"
          >
            <b-form-datepicker
              id="edate"
              v-model="filterData.edate"
              v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="12"
          class="text-center"
        >
          <b-button
            variant="primary"
            target="_blank"
            :disabled="!filterData.sdate || !filterData.edate"
            @click="getReport"
          >
            <FeatherIcon icon="DownloadIcon" />
            Raporu Getir
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <template v-if="loading">
      <loading />
    </template>
    <template v-else>
      <b-card
        v-if="reportData.report.length >0"
        no-body
      >
        <b-card-header>
          <b-card-title>
            Rapor Verileri
          </b-card-title>
        </b-card-header>
        <work-orders-table />
      </b-card>

    </template>
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BFormDatepicker, BFormGroup, BRow, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'
import WorkOrdersTable from '@/views/Reports/Service/ServiceWorkOrders/WorkOrdersTable.vue'

export default {
  name: 'ServiceWorkOrderReport',
  components: {
    WorkOrdersTable,
    Loading,
    BFormDatepicker,
    BFormGroup,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
  },
  computed: {
    filterData() {
      return this.$store.getters['serviceWorkOrderReport/getFilterData']
    },
    reportData() {
      return this.$store.getters['serviceWorkOrderReport/getReport']
    },
    loading() {
      return this.$store.getters['serviceWorkOrderReport/getLoading']
    },
  },
  created() {
    this.$store.commit('serviceWorkOrderReport/RESET_REPORT_DATA')
    this.$store.commit('serviceWorkOrderReport/RESET_REPORT_FILTER_DATA')
  },
  methods: {
    getReport() {
      this.$store.dispatch('serviceWorkOrderReport/report', this.filterData)
    },
  },
}
</script>
