<template>
  <div>
    <b-table-simple
      small
    >
      <b-thead>
        <b-tr class="bg-light-warning">
          <b-th class="font-small-2 bg-transparent" />
          <b-th class="font-small-2 bg-transparent">
            Marka
          </b-th>
          <b-th class="font-small-2 width-200 bg-transparent">
            İş Emri Adedi
          </b-th>
          <b-th class="font-small-2 width-200 bg-transparent">
            SÜRE / DAKİKA
          </b-th>
          <b-th class="font-small-2 width-200 bg-transparent">
            SÜRE / SAAT
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(line,key) in reportData.report[dataKey].brands">
          <b-tr
            :key="key"
          >
            <b-td class="font-small-2 text-center p-0 width-50">
              <b-button
                variant="flat-warning"
                size="sm"
                pill
                class="btn-icon"
                @click="line.statusButton = !line.statusButton"
              >
                <template
                  v-if="line.statusButton"
                >
                  <FeatherIcon icon="EyeOffIcon" />
                </template>
                <template v-else>
                  <FeatherIcon icon="EyeIcon" />
                </template>
              </b-button>
            </b-td>
            <b-td class="font-small-2">
              {{ line.title }}
            </b-td>
            <b-td class="font-small-2">
              {{ line.orders |toNumber }} Adet
            </b-td>
            <b-td class="font-small-2">
              {{ line.minutes | toNumber }} Dakika
            </b-td>
            <b-td class="font-small-2">
              {{ line.hours | toNumber }} Saat
            </b-td>
          </b-tr>
          <b-tr
            v-if="line.statusButton"
            :key="'detail_' + key"
          >
            <b-td
              colspan="5"
              class="m-0 p-0"
            >
              <work-order-worker-table
                :data-key="dataKey"
                :brand-key="key"
              />
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTh, BThead, BTbody, BTr, BTd, BButton,
} from 'bootstrap-vue'
import WorkOrderWorkerTable from '@/views/Reports/Service/ServiceWorkOrders/WorkOrderWorkerTable.vue'

export default {
  name: 'WorkOrderBrandTable',
  components: {
    WorkOrderWorkerTable,
    BButton,
    BTableSimple,
    BTh,
    BThead,
    BTbody,
    BTr,
    BTd,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    reportData() {
      return this.$store.getters['serviceWorkOrderReport/getReport']
    },
  },
}
</script>
