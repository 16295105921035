<template>
  <div>
    <b-table-simple
      small
      striped
    >
      <b-thead>
        <b-tr class="bg-light-info">
          <b-th class="font-small-2 bg-transparent" />
          <b-th class="font-small-2 bg-transparent">
            Gün
          </b-th>
          <b-th class="font-small-2 width-200 bg-transparent">
            İş Emri Adedi
          </b-th>
          <b-th class="font-small-2 width-200 bg-transparent">
            SÜRE / DAKİKA
          </b-th>
          <b-th class="font-small-2 width-200 bg-transparent">
            SÜRE / SAAT
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="(line,key) in reportData.report[dataKey].brands[brandKey].workers[workerKey].weeks[weekKey].days">
          <b-tr :key="key">
            <b-td class="width-50" />
            <b-td class="font-small-2">
              {{ line.title }}
            </b-td>
            <b-td class="font-small-2">
              {{ line.orders |toNumber }} Adet
            </b-td>
            <b-td class="font-small-2">
              {{ line.minutes | toNumber }} Dakika
            </b-td>
            <b-td class="font-small-2">
              {{ line.hours | toNumber }} Saat
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>
<script>
import {
  BTableSimple, BTh, BThead, BTbody, BTr, BTd,
} from 'bootstrap-vue'

export default {
  name: 'WorkOrderDayTable',
  components: {
    BTableSimple,
    BTh,
    BThead,
    BTbody,
    BTr,
    BTd,
  },
  props: {
    dataKey: {
      type: Number,
      required: true,
    },
    brandKey: {
      type: Number,
      required: true,
    },
    workerKey: {
      type: Number,
      required: true,
    },
    weekKey: {
      type: Number,
      required: true,
    },
  },
  computed: {
    reportData() {
      return this.$store.getters['serviceWorkOrderReport/getReport']
    },
  },
}
</script>
